<template>
  <section>
    <div class="all-devices ">
      <b-card no-body>
        <div class="m-2 mb-0">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <v-select
                v-model="paginationData.perPage"
                dir="ltr"
                :options="[3, 5, 8, 10]"
                :clearable="false"
                label="10"
                class="per-page-selector d-inline-block mx-50"
                @input="paginationChanged(paginationData.currentPage = 1)"
              />
              <label>Devices</label>
            </b-col>
            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchDevices"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                  @keyup.enter="getAllDevices"
                  @change="paginationData.currentPage = 1"
                />
                <b-button
                  variant="primary"
                  @click="getAllDevices"
                >
                  Search
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-skeleton-table
          v-if="spinner"
          :rows="paginationData.perPage"
          :columns="3"
          :table-props="{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }"
        />
        <div
          v-else
          class="heading-elements ml-0"
        >
          <b-table
            id="devices-table"
            :per-page="paginationData.perPage"
            :items="allDevices"
            responsive
            :fields="tableColumns"
            show-empty
            class="float: right"
          >
            <!-- Column: name -->
            <template #cell(Devices)="data">
              <b-link>
                <span class="d-block text-nowrap devices">
                  {{ data.item.name }}
                </span>
              </b-link>
            </template>
            <!-- Column: Groups -->
            <template #cell(Group)="data">
              <span
                v-for="device_has_group in data.item.device_has_group"
                :key=" device_has_group.id"
              >
                <div
                  v-if="spinners[data.item.id + '' + device_has_group.device_groups.id]"
                  class="spinner"
                >
                  <b-spinner
                    variant="primary"
                    small
                  />
                </div>
                <b-badge
                  v-else
                  href="#"
                  class="group-badge"
                  variant="light"
                >
                  {{ device_has_group.device_groups.name }}
                </b-badge>
              </span>
            </template>
            <!-- Column: Status -->
            <template
              #cell(BANNER-MODE)="data"
              class="BannerMode con-switch"
            >
              <span class="float-right">
                <div
                  v-if="childSpinner[data.item.mac_address]"
                  style="width: 75px;"
                  class="spinner text-center"
                >
                  <b-spinner
                    variant="primary"
                    small
                  />
                </div>
                <vs-switch
                  v-else
                  disabled
                  :value="data.item.is_banner_ad_mode_enabled"
                  class="switchs"
                  :style="`background-color: ${data.item.is_banner_ad_mode_enabled ? '#00E265' : '#FF0000'};color:white;`"
                >
                  <span
                    slot="on"
                    style="font-weight: bold"
                  >Disable</span>
                  <span
                    slot="off"
                    style="font-weight: bold"
                  >Enable</span>
                </vs-switch>
              </span>
            </template>
          </b-table>
        </div>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
            >
              <span
                class="text-muted"
              />
            </b-col>
            <!-- pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
            >
              <b-pagination
                :value="paginationData.currentPage"
                :total-rows="paginationData.totalRecords"
                :per-page="paginationData.perPage"
                hide-goto-end-buttons
                class="mb-0 mt-1 mt-sm-0"
                aria-controls="devices-table"
                size="sm"
                pills
                @change="paginationChanged"
              />
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </section>
</template>
<script>
import {
  BRow,
  BCol,
  BTable,
  BLink,
  BPagination,
  BSpinner,
  BBadge,
  BSkeletonTable,
  BButton,
  BFormInput,
  VBToggle,
  BCard,
} from 'bootstrap-vue'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import vSelect from 'vue-select'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BPagination,
    BSpinner,
    BBadge,
    BSkeletonTable,
    BButton,
    BFormInput,
    vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      spinner: false,
      searchDevices: '',
      allDevices: [],
      tableColumns: [
        { key: 'Devices' },
        { key: 'Group' },
        {
          key: 'BANNER-MODE',
          thClass: 'pull-right text-right textTransform hide',
        },
      ],
      spinners: {},
      childSpinner: {},
      paginationData: {
        currentPage: 1,
        perPage: 5,
        totalRecords: 0,
      },
    }
  },
  async mounted() {
    await this.getAllDevices()
  },
  methods: {
    openDevicesGroup() {
      this.$refs['add-devices-group'].show()
    },
    async paginationChanged(offset) {
      this.paginationData.currentPage = offset
      await this.getAllDevices()
    },
    getAllDevices() {
      this.spinner = true
      this.$axios.get(`admin/device/${this.$route.params.userId}?offset=${this.paginationData.currentPage - 1}&limit=${this.paginationData.perPage}&search_query=${this.searchDevices}`)
        .then(({ data }) => {
          if (this.paginationData.currentPage > 1 && data.data.results.length === 0) {
            this.paginationChanged(this.paginationData.currentPage - 1)
            return
          }
          this.allDevices = data.data.results
          this.paginationData.totalRecords = data.data.total
          this.spinner = false
        }).catch(() => this.$swal('Device not found!'))
    },
  },
}
</script>
<style lang="scss">
.all-devices{
  .group-badge{
    color: #000000;
    cursor: default;
    font-size: 12px;
    margin: 5px;
  }
  .device-delete-icon{
    font-weight: 400;
    color: #6e6b7b;
    margin-left: 3px;
    cursor: pointer;
    transition: all 0.5s;
    &:hover{
      color: blue;
    }
  }
  .card:last-of-type{
    margin-bottom: 10px;
  }
}
.per-page-selector {
  width: 90px;
}

.vs__actions {
  font-weight: 100 !important;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
.switchs {
  width: 50px;
  padding-inline: 40px;
  border-radius: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
  // background-color: #7FF0B2;
  color: white;
}
.devices{
  font-size: 14px;
  line-height: 11px;
  color: #1f58b5;
  font-weight: 500;
  font-family: "Montserrat";
}
</style>
